import {cfConfig} from "../utils/cfUtils";
import React from "react";
import {useSelector} from "react-redux";

const CategoriesView = () => {
    const categories = useSelector(state => state.categories.list)

    return(
        <React.Fragment>
            {
                categories.length > 0 ?
                categories.map((item, index) => (
                    <li className={`item`} key={index}>
                        <a href={cfConfig.baseUri + item.category_url_s}>
                            <div dangerouslySetInnerHTML={{__html: "Go to " + item.parent_path_names_s}}/>
                        </a>
                    </li>
                ))
                : ''
            }
        </React.Fragment>
    )
}

export default CategoriesView
