/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import SolrSuggest from './SolrSuggestField';
import {Provider} from "react-redux";
import configureStore from "./store/configureStore";

const store = configureStore()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SolrSuggest />
    </Provider>
  </React.StrictMode>,
  document.getElementById('solrSearchSuggestor')
);
