import {createSlice} from "@reduxjs/toolkit";
import {apiCallBegan} from "./api";
import {cfConfig} from "../utils/cfUtils";

const slice = createSlice({
    name: 'suggestions',
    initialState: {
        list: [],
        loading: false
    },
    reducers: {
        suggestionsRequested: (suggestions) => {
            suggestions.loading = true
        },
        suggestionsRequestFailed: (suggestions) => {
            suggestions.loading = false
        },
        suggestionsReceived: (suggestions, action) => {
            // Get the query string from the object
            const queryKey = Object.keys(action.payload.suggest.default)
            suggestions.list = action.payload.suggest.default[queryKey].suggestions
            suggestions.loading = false
        }
    }
})

const {suggestionsReceived, suggestionsRequested, suggestionsRequestFailed} = slice.actions
export default slice.reducer

// Action Creators
export const getSuggestions = (term) => (
    apiCallBegan({
        baseURL: cfConfig.shopUri,
        url: "/suggest/Products?q=" + term,
        onStart: suggestionsRequested.type,
        onError: suggestionsRequestFailed.type,
        onSuccess: suggestionsReceived.type
    })
)
