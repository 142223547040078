import React from "react";
import {cfConfig} from "../utils/cfUtils";
import {useSelector} from "react-redux";
import './style.css'

const ProductDetailsView = () => {
    const productDetails = useSelector(state => state.productDetails.list)

    return (
        <React.Fragment>
        {productDetails.length > 0  &&
            <div className={`product-details`}>
                 <table width={'100%'} cellPadding={`0`} cellSpacing={`0`}>
                     <tbody>
                     {productDetails.map((item, index) => (
                       <tr key={index} onClick={() => {
                         window.location.href = cfConfig.baseUri + item.product_url_s
                       }} className={`linkrow`}>
                         <td valign={'middle'} width={'100px'}>
                           <img
                             src={item.image_thumb_web_s ? cfConfig.baseUri + item.image_thumb_web_s : cfConfig.baseUri + '/images/noimagethumb.gif'}
                             height={'60px'}
                             alt={item.web_description_s}/>
                         </td>
                         <td valign={'middle'} dangerouslySetInnerHTML={{__html: item.web_description_s}}/>
                       </tr>
                     ))}
                    </tbody>
                </table>
            </div>
        }
        </React.Fragment>
    )
}

export default ProductDetailsView
