import {createSlice} from "@reduxjs/toolkit";
import {apiCallBegan} from "./api";
import {cfConfig} from "../utils/cfUtils";

const slice = createSlice({
    name: 'categories',
    initialState: {
        list: [],
        loading: false
    },
    reducers: {
        categoriesRequested: (categories) => {
            categories.loading = true
        },
        categoriesRequestFailed: (categories) => {
            categories.loading = false
        },
        categoriesReceived: (categories, action) => {
            // Get the query string from the object
            categories.list = action.payload.response.docs.slice(0,cfConfig.categoriesCount)
            categories.loading = false
        }
    }
})

const {categoriesReceived, categoriesRequested, categoriesRequestFailed} = slice.actions
export default slice.reducer

// Action Creators
export const getCategories = (term) => (
    apiCallBegan({
        baseURL: cfConfig.shopUri,
        url: "/select/Categories?q=" + term,
        onStart: categoriesRequested.type,
        onError: categoriesRequestFailed.type,
        onSuccess: categoriesReceived.type
    })
)
