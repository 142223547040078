import {pipe} from "lodash/fp"

// Load up the cfConfig object
export const cfConfig = window.cfConfig

export const cfRemoveHtmlElements = input => { return input.replace(/<[^>]*>?/gm, '') }
export const cfReplaceSpaceChars = input => { return input.replace(/(%20)/g, '+') }
export const cfTrimString = input => { return input.trim() }

// Strip out the HTML tags and trim the whitespace, URI encode things, then replace %20 with + for escript search
export const cfSearchEncoded = (term) => {
    const convert = pipe(cfRemoveHtmlElements, cfTrimString, encodeURIComponent, cfReplaceSpaceChars)
    return convert(term)
}
