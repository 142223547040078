import React from "react";
import CategoriesView from '../CategoriesView'
import {cfConfig, cfSearchEncoded} from "../utils/cfUtils";
import {useSelector} from "react-redux";
import './styles.css'

const SuggestionsView = ({query}) => {
    const suggestions = useSelector(state => state.suggestions.list)
    const productDetails = useSelector(state => state.productDetails.list)

    return (
        <div className={`suggestions ${ productDetails.length > 0 && "half" }`}>
            <ul>
                <li className={`suggestions-item`}>
                    <a href={cfConfig.shopUri + "/search-results.html?searchvalue="+ cfSearchEncoded(query)}>
                        <div>
                            Search store for <b>{query}</b>
                        </div>
                    </a>
                </li>
                {/* Add the categories here */}
                <CategoriesView />
                {
                    suggestions.length > 0 ?
                    suggestions.map((item, index) => (
                        <li className={`item`} key={index}>
                            <a href={cfConfig.shopUri + "/search-results.html?searchvalue="+ cfSearchEncoded(item.term)}>
                                <div dangerouslySetInnerHTML={{__html: item.term}} />
                            </a>
                        </li>
                    ))
                    : ''
                }
            </ul>
        </div>
    )
}

export default SuggestionsView
