import {createSlice} from "@reduxjs/toolkit";
import {apiCallBegan} from "./api";
import {cfConfig} from "../utils/cfUtils";

const slice = createSlice({
    name: 'productDetails',
    initialState: {
        list: [],
        loading: false
    },
    reducers: {
        productDetailsRequested: (productDetails) => {
            productDetails.loading = true
        },
        productDetailsRequestFailed: (productDetails) => {
            productDetails.loading = false
        },
        productDetailsReceived: (productDetails, action) => {
            // Only ever return the top 3 results
            productDetails.list = action.payload.response.docs.slice(0,cfConfig.productDetailsCount)
            productDetails.loading = false
        }
    }
})

const {productDetailsReceived, productDetailsRequested, productDetailsRequestFailed} = slice.actions
export default slice.reducer

// Action Creators
export const getProductDetails = (term) => (
    apiCallBegan({
        baseURL: cfConfig.shopUri,
        url: "/spell/Products?q=" + term,
        onStart: productDetailsRequested.type,
        onError: productDetailsRequestFailed.type,
        onSuccess: productDetailsReceived.type
    })
)
