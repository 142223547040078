import React, {useEffect, useRef, useState} from 'react'
import SuggestionsView from "../SuggestionsView";
import ProductDetailsView from "../ProductDetailsView";
import {cfConfig, cfSearchEncoded} from "../utils/cfUtils";
import {useDispatch} from "react-redux";
import {getSuggestions} from "../store/suggestions";
import {getProductDetails} from "../store/productDetails";
import {getCategories} from "../store/categories";
import './style.css'

require("es6-promise").polyfill()
require("isomorphic-fetch")

// Add isEmpty function to Strings
String.prototype.isEmpty = function() {
    return (this.length === 0 || !this.trim());
};

const SolrSuggest = () => {
    const dispatch = useDispatch()
    const [query, setQuery] = useState("")
    const [isVisible, setVisibility] = useState(false)
    const searchBox = useRef(null)

    // Run once when component loads
    useEffect(() => {
        window.addEventListener("mousedown", clickOutsideSearchBox)
        return () => { window.removeEventListener("mousedown", clickOutsideSearchBox) }
    },[])

    // Run when query changes
    useEffect(() => {
        if (query.isEmpty()) {
            hideResults()
        } else {
            dispatch(getSuggestions(query))
            if (cfConfig.productDetails) dispatch(getProductDetails(query))
            if (cfConfig.categories) dispatch(getCategories(query))
        }
        // query.isEmpty() ? hideResults() : dispatch(getSuggestions(query)); dispatch(getProductDetails(query));
        if (!isVisible) showResults()
    }, [query])

    const clickOutsideSearchBox = e => {
        if (searchBox.current && !searchBox.current.contains(e.target))
            hideResults()
    }

    const doSearch = e => {
        // Just return on empty query
        if (query.length <= 0) return
        // Only trigger if we get Enter or a click
        if (e.Key === "Enter" || e.keyCode === 13 || e.type === "click")
            window.location.href = cfConfig.shopUri + "/search-results.html?searchvalue=" + cfSearchEncoded(query)
    }

    const showResults = () => { if (query.trim().length > 0) setVisibility(true )}
    const hideResults = () => { setVisibility(false )}

    return (
        <div className={`autocomplete`} style={{ height: "100%" }} ref={searchBox}>
            <div className={`ac-field`}>
                <input
                    id={`search`}
                    type="text"
                    name="search"
                    value={query}
                    onClick={showResults}
                    onKeyUp={doSearch}
                    onChange={(e) => setQuery(e.target.value)}
                    className="search-box"
                    autoComplete="off" />
                <button
                    className={`ac-submit`}
                    type={`submit`}
                    onClick={doSearch}>
                    Search
                </button>
            </div>

            <div className={`ac-results ${ isVisible ? "visible": "invisible" }`}>
                {/*Show the suggestions*/}
                <SuggestionsView query={query} />
                {/*Show the product details*/}
                { cfConfig.productDetails ? <ProductDetailsView /> : '' }
            </div>
        </div>
    );
}

export default SolrSuggest
