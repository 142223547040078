import axios from 'axios'
import * as actions from '../api'

const api = ({dispatch}) => next => async action => {
    if (action.type !== actions.apiCallBegan.type) return next(action)

    const {baseURL,url,method,data,onStart,onSuccess,onError} = action.payload

    /* istanbul ignore else */
    if (onStart)
        dispatch({type: onStart})

    // Fire the next action (always need this)
    next(action)

    try {
        // url = /CorbyFellasProducts?q=blah
        const response = await axios.request({
            baseURL,
            url,
            method,
            data
        })
        dispatch(actions.apiCallSuccess(response.data))
        /* istanbul ignore else */
        if (onSuccess)
            dispatch({type: onSuccess, payload: response.data})
    } catch (error) {
        dispatch(actions.apiCallFailed(error.message))
        /* istanbul ignore else */
        if (onError)
            dispatch({type: onError, payload: error.message})
    }
}

export default api
