import {combineReducers} from "redux";
import suggestionsReducer from './suggestions'
import productDetailsReducer from './productDetails'
import categoriesReducer from './categories'

export default combineReducers({
    suggestions: suggestionsReducer,
    productDetails: productDetailsReducer,
    categories: categoriesReducer
})
